import { mapGetters, mapActions } from 'vuex'
import authMixin from '@/mixins/auth-mixin'
const WorshipItem = () => import(/* webpackChunkName = "c-worship-item" */'@/components/WorshipItem')
const AddWorshipForm = () => import(/* webpackChunkName = "c-add-worship-form" */'@/components/AddWorshipForm')

export default {
  name: 'Worship',
  mixins: [authMixin],
  components: {
    WorshipItem,
    AddWorshipForm
  },
  data () {
    return {
      visibleAddForm: false,
      editData: {}
    }
  },
  created () {
    this.getWorshipsAction({
      success: () => {}
    })
  },
  computed: {
    ...mapGetters('user', ['token']),
    ...mapGetters('worship', ['worships'])
  },
  methods: {
    ...mapActions('worship', ['getWorshipsAction']),
    handleSuccessDelete () {
      this.$swal({
        icon: 'success',
        title: 'Success Delete',
        showConfirmButton: false,
        timer: 1500
      })
    },
    handleEditWorship (data) {
      this.editData = { ...data }
      this.toggleAddForm()
    },
    toggleAddForm () {
      this.visibleAddForm = !this.visibleAddForm
    }
  }
}
